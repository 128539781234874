/* eslint-disable @typescript-eslint/naming-convention */
import { SafeStyle } from '@angular/platform-browser';

export interface SiteContent {
    id: number;
    active: string;
    title: string;
    headline: string;
    description: string;
    weight: string;
    ['background-image']: SafeStyle;
    type: string;
    route: string;
    value: string;
    children: any[];
}

export interface NavItem extends SiteContent {
    icon?: Icon;
    subtitle?: string;
    url?: string;
}

interface Icon {
  icon: string;
  title: string;
  'title-translated': string;
}

export interface Gallery extends NavItem {
    products: Product[];
}

export interface Product {
    'pdb-id': string;
    title: string;
    'color-configurators': any;
    'console-pdb-id': string;
    '360-viewers': any;
    'hero-image': string;
}

export interface ColorConfig extends SiteContent {
    frame: ColorConfigItem[];
    mergedFrameUpholstery: ColorConfigItem[];
    upholstery: ColorConfigItem[];
}

export interface ColorConfigItem {
    'color-hex-code': string;
    'color-title': string;
    'asset-image': string;
    upholstery?: ColorConfigItem[];
}

export interface Video extends SiteContent {
    video: string;
}

export interface ThreeSixty extends SiteContent {
    ['images-24']: { image: string };
}

export interface User {
    ['created_at']: string;
    email: string;
    ['email_verified']: boolean;
    name: string;
    nickname: string;
    picture: string;
    ['updated_at']: string;
    ['user_id']: string;
    ['user_metadata']: {
        region: string;
    };
    ['last_login']: string;
    ['logins_count']: number;
    password: string;
}

interface Translation {
    keyName: {
        'is_markdown': boolean;
        key: string;
        value: string;
    };
};
export interface TranslationData {
    langRegion: Translation;
}

export interface Config {
    country: string;
    defaultPreferredLanguage: string;
    id: string;
    languages: Record<string, { fallback: string[] }>;
}

export enum Env {
    prod = 'prod',
    qa = 'qa',
    dev = 'dev',
    test = 'test'
}

export enum ModalNav {
    prev = 'prev',
    next = 'next',
}

export const apiUrl = {
    dev: 'http://localhost:8100',
    qa: 'https://brand-api-qa.jfit.co',
    staging: 'https://brand-api-staging.jfit.co',
    prod: 'https://brand-api.jfit.co'
};

export const mediaDirectory = {
    externalMedia: '',
    externalIcons: '',
    externalCombined: 'https://images.jhtassets.com/mea/combined/',
    localMedia: 'assets/media',
    localIcons: 'assets/icons',
    localImages: 'assets/images',
};

export const dataDirectory = {
    local: 'assets/data',
    external: `https://mea-cms-data.s3.us-east-2.amazonaws.com`,
};


export const  componentsDirectory = 'components/v2';
export const translationsDirectory = 'translations/v2';

export const appDownloadUrl = 'https://mea-app.s3.us-east-2.amazonaws.com';

export const appWebsiteUrl = {
    dev: 'dev.matrixexperienceapp.com',
    qa: 'qa.matrixexperienceapp.com',
    prod: 'www.matrixexperienceapp.com',
};

export const APP_STATE_KEYS = {
    DATA_SYNC_STARTED: 'DATA_SYNC_STARTED',
    DATA_SYNC_COMPLETED: 'DATA_SYNC_COMPLETED',
    DATA_SYNC_CONTENT_COMPLETED: 'DATA_SYNC_CONTENT_COMPLETED',
    DATA_LAST_CHECKED: 'DATA_LAST_CHECKED',
    DATA_LAST_UPDATED: 'DATA_LAST_UPDATED',
    DATA_IS_OUTDATED: 'DATA_IS_OUTDATED',
    APP_IS_OUTDATED: 'APP_IS_OUTDATED',
    APP_LAST_VERSION: 'APP_LAST_VERSION',
};

export interface StateEntry {
    key: string;
    value: string;
};

export const regionMap = (region: string) => {
    const _region = region.toUpperCase();

    const map = {
        WLD: 'world',
        GLB: 'world',
        USA: 'us'
    };

    return map[_region] || region.toLowerCase();
};

export const meaDefaultRegion = 'world';
export const globalFallbackRegLang = 'eng-WLD';

export const analyticsEndpointProd = 'https://l7ntj1tx4k.execute-api.us-east-2.amazonaws.com/prod/mea2/send-analytics';
