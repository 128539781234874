/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Params } from '@angular/router';

import { Observable, Subject, lastValueFrom } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { TranslateService } from '@jht/brand-translate';

import { Gallery, SiteContent, ThreeSixty, Video } from '../models';
import { DataService } from './data.service';
import { FileService } from './file.service';
import { DataRoutingService } from './data-routing.service';

@Injectable({
    providedIn: 'root',
})
export class ContentService {
    pageData$: Subject<any> = new Subject();

    isHome: boolean;
    params: Params;

    constructor(
        private dataService: DataService,
        private dataRoutingService: DataRoutingService,
        private fileService: FileService,
        private domSanitizer: DomSanitizer,
        private translateService: TranslateService
    ) {
        this.initNavService();
    }

    initNavService(): void {
        this.dataRoutingService.params$
            .pipe(filter((items) => !!items.category))
            .subscribe(async (param) => {
                this.params = param;
                const type: string = param.category
                    ? param.category === 'home'
                        ? param.category
                        : 'category'
                    : {};

                this.isHome = type === 'home';
                const content: any = await lastValueFrom(
                    this.getSiteContentByType(type)
                );

                const filteredData = this.parseContentByParam(
                    content,
                    param
                )[0];

                this.pageData$.next(filteredData);
            });
    }

    getGalleryPageContent(): Observable<Gallery> {
        return this.pageData$.pipe(
            map(
                (data: SiteContent) =>
                    this.getChildrensChildrenByRoute(data, 'gallery')?.filter(
                        (gallery) =>
                            gallery.route === this.params['gallery-title']
                    )[0]
            )
        );
    }

    getThreeSixtyPageContent(): Observable<ThreeSixty> {
        return this.getPageContentByParam(
            'three-sixty',
            'threeSixty-title'
        ).pipe(filter((threeSixtyData) => !!threeSixtyData));
    }

    getColorConfigPageContent(): Observable<SiteContent> {
        return this.getPageContentByParam(
            'color-configurator',
            'configurator-title'
        ).pipe(filter((colorConfigData) => !!colorConfigData));
    }

    getVideoPageContent(): Observable<Video> {
        return this.getPageContentByParam('video', 'video-title');
    }

    getGridPageContent(): Observable<any> {
        return this.pageData$.pipe(
            mergeMap(async (data: SiteContent) => {
                const pageContent: SiteContent = { ...data };
                pageContent.headline = this.getTranslationIfExists(
                    pageContent.headline
                );
                pageContent['background-image'] =
                    await this.getAndSanitizeBGImage(
                        pageContent['background-image'] as string
                    );

                return pageContent;
            })
        );
    }

    getNavigation(): Observable<any> {
        return this.dataService.getNavData();
    }

    private getTranslationIfExists(value: string) {
        const keyVal = this.translateService.instant(value);
        return keyVal === value ? value : keyVal;
    }

    private getChildrensChildrenByRoute(data, route: string) {
        return data.children.filter((items) => items.route === route)[0]
            ?.children;
    }

    private getChildren(data: any, param: Params): SiteContent[] {
        return data.children.filter((items) => items.route === param);
    }

    private getPageContentByParam(
        route: string,
        param: string
    ): Observable<any> {
        return this.pageData$.pipe(
            map((data: SiteContent) => {
                const pageContent = this.getChildrensChildrenByRoute(
                    data,
                    route
                );

                const result = pageContent
                    ? pageContent.filter(
                          (item: any) => item.route === this.params[param]
                      )
                    : [];
                return result[0];
            })
        );
    }

    async getBGImage(bgImage: string): Promise<string> {
        const urlBg = this.getTranslationIfExists(bgImage);
        const file: string = urlBg ? urlBg : '';
        const imagePath: string = await this.fileService.getFilePath(
            file,
            'media',
            true
        );
        return imagePath;
    }

    async getAndSanitizeBGImage(bgImage: string): Promise<SafeStyle> {
        const imagePath = await this.getBGImage(bgImage);
        return this.domSanitizer.bypassSecurityTrustStyle(
            'url(' + imagePath + ')'
        );
    }

    getSiteContentByType(type: string) {
        return this.dataService
            .getAllData()
            .pipe(map((data) => data.filter((e: any) => e.type === type)));
    }

    private parseContentByParam(
        data: SiteContent[],
        params: Params
    ): SiteContent[] | Record<string, unknown> {
        let result: SiteContent[] | Record<string, unknown>;
        result = data.filter((items) => items.route === params.category);
        if (params['level-1']) {
            result = this.getChildren(result[0], params['level-1']);
            if (params['level-2']) {
                result = this.getChildren(result[0], params['level-2']);
                if (params['level-3']) {
                    result = this.getChildren(result[0], params['level-3']);
                }
            }
        }
        return result;
    }
}
