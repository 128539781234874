import { Component, OnInit } from '@angular/core';

import { AuthenticationService } from '../../../services/authentication.service';
import { NavItem } from '../../../models';
import { ContentService } from '../../../services/content.service';
import { DataRoutingService } from 'src/app/services/data-routing.service';
import { DataService } from 'src/app/services/data.service';

@Component({
    selector: 'mea-menu',
    templateUrl: 'menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
    links: NavItem[] = [];
    region: string;

    constructor(
        private authenticationService: AuthenticationService,
        private contentService: ContentService,
        private dataService: DataService
    ) {}

    ngOnInit() {
        this.contentService
            .getNavigation().subscribe((navItems) => this.links = navItems);
        this.region = this.dataService.country;
    }

    logOut() {
        this.authenticationService.logout();
    }
}
