import { Env, apiUrl} from '../app/models';
import packageJson from '../../package.json';

export const environment = {
    production: true,
    name: Env.dev,
    apiUrl: apiUrl.dev,
    bapiEnv: 'qa',
    version: packageJson.version,
    analyticsEndpoint: ''
};
